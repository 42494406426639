import { render, staticRenderFns } from "./FeaturedDetail.vue?vue&type=template&id=2bf65926&scoped=true&"
import script from "./FeaturedDetail.vue?vue&type=script&lang=js&"
export * from "./FeaturedDetail.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedDetail.vue?vue&type=style&index=0&id=2bf65926&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bf65926",
  null
  
)

export default component.exports